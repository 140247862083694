import { AgentRepository } from './agent.repository';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agent } from '../models/agent';
import { map } from 'rxjs/operators';

export class AgentFsRepository implements AgentRepository {
  http = inject(HttpClient);

  getAgent(): Observable<Agent[]> {
    return this.http.get<{ agents: Agent[] }>('assets/datas/agents.json').pipe(map(data => data.agents));
  }
}
