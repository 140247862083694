import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'app/shared/services/config.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  apiUrl: string;
  authInterceptorWhiteList: string[];

  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
    private translateService: TranslateService,
    private configService: ConfigService,
  ) {
    this.apiUrl = this.configService.readConfig().apiUrl;
    this.authInterceptorWhiteList = ['https://api-adresse.data.gouv.fr/search/', `${this.apiUrl}/auth/forget-password`];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headersConfig: any = {};
    const token = this.tokenService.get();
    if (token) {
      if (!this.authInterceptorWhiteList.includes(req.url)) {
        headersConfig = { ...headersConfig, Authorization: token };
      }
    }

    const request = req.clone({ setHeaders: headersConfig });

    // Handle response
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // If our token is expired, force logout to set login id to NULL
            if (err.status == 401 && err.error.msg === 'token expired') {
              this.authService
                .logOut()
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                  data => {
                    if (data) {
                      this.toastrService.error(
                        this.translateService.instant('toastr.session_expired'),
                        this.translateService.instant('toastr.error'),
                      );
                      this.router.navigate(['/auth/signin']);
                    }
                  },
                  err => {
                    console.error(err);
                  },
                );
              // If our token is not expired but session is expired, force logout without set login id to NULL
            } else if (err.status == 401 && err.error.msg === 'login id not found') {
              this.toastrService.error(
                this.translateService.instant('toastr.session_expired'),
                this.translateService.instant('toastr.error'),
              );
              this.router.navigate(['/auth/signin']);
            } else if (err.status == 401 && err.error.msg === 'token not found') {
              this.toastrService.error(
                this.translateService.instant('toastr.session_expired'),
                this.translateService.instant('toastr.error'),
              );
              this.router.navigate(['/auth/signin']);
            } else {
              console.error('error', err);
            }
          }
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
