import { Directive, ElementRef, inject, Renderer2 } from '@angular/core';

@Directive({
  selector: '[mat-dialog-actions]',
})
export class MatDialogActionsAlignDirective {
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  constructor() {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'align', 'end');
  }
}
