import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirm-action-by-password',
  templateUrl: './confirm-action-by-password.component.html',
  styleUrls: ['./confirm-action-by-password.component.scss'],
})
export class ConfirmActionByPasswordComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  passwordForm: FormGroup;
  showPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmActionByPasswordComponent>,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  buildForm() {
    this.passwordForm = this.formBuilder.group({
      password: [null, Validators.required],
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    if (this.password.value === 'incident') {
      this.dialogRef.close(true);
    } else {
      this.toastrService.error(
        this.translateService.instant('toastr.failed'),
        this.translateService.instant('common.wrong_password'),
      );
    }
  }

  get password() {
    return this.passwordForm.get('password');
  }

  showHidePassword(): void {
    this.showPassword = !this.showPassword;
  }
}
