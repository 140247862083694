import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppDropdownDirective } from './dropdown.directive';
import { DropdownAnchorDirective } from './dropdown-anchor.directive';
import { DropdownLinkDirective } from './dropdown-link.directive';
import { TwoDigitDecimalDirective } from './two-digit-decimal.directive';
import { UpdateDateRangeValueAndValidityFixerDirective } from './update-date-range-value-and-validity-fixer.directive';
import { MatDialogActionsAlignDirective } from './mat-dialog-actions-align.directive';

const directives = [
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  TwoDigitDecimalDirective,
  UpdateDateRangeValueAndValidityFixerDirective,
  MatDialogActionsAlignDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class SharedDirectivesModule {}
