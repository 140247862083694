import { RouteRepository } from './route.repository';
import { Route } from '../models/route';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { format, isValid, parse } from 'date-fns';

interface RoutesByDate {
  date: string;
  routes: Route[];
}

export class RouteFsRepository implements RouteRepository {
  httpClient = inject(HttpClient);

  getRoutesByDate(date: string): Observable<Route[]> {
    if (!this.validateDateYYYYMMDD(date)) {
      throw new Error('Date must be in format YYYY-MM-DD');
    }
    return this.httpClient.get<RoutesByDate[]>('assets/datas/route.json').pipe(
      map((routes: RoutesByDate[]) => {
        const routeFind = routes.find((route: RoutesByDate) => {
          return route.date === date;
        });
        return routeFind?.routes;
      }),
    );
  }

  validateDateYYYYMMDD(dateString: string): boolean {
    const date = parse(dateString, 'yyyy-MM-dd', new Date());
    return isValid(date) && dateString === format(date, 'yyyy-MM-dd');
  }
}
