import { Route } from '../models/route';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface RouteRepository {
  getRoutesByDate(date: string): Observable<Route[]>;
}

export const ROUTE_REPOSITORY: InjectionToken<RouteRepository> = new InjectionToken<RouteRepository>(
  'ROUTE_REPOSITORY',
);
