import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { CoreModule } from './core/core.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { MqttModule, MqttService } from 'ngx-mqtt';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { ConfigService } from './shared/services/config.service';
import { AGENT_REPOSITORY, AgentFsRepository, ROUTE_REPOSITORY, RouteFsRepository } from '@drivers';

registerLocaleData(localeFr);
registerLocaleData(localeEn);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const appInitializerFn = (configService: ConfigService) => {
  return () => {
    return configService.setConfig();
  };
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    CoreModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      exclude: ['https://api-adresse.data.gouv.fr/search/', `${GetConfig().apiUrl}/itineraries-tracking`],
    }),
    MqttModule.forRoot({
      connectOnCreate: false,
    }),
  ],
  declarations: [AppComponent],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    MqttService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService],
    },
    {
      provide: ROUTE_REPOSITORY,
      useClass: RouteFsRepository,
    },
    {
      provide: AGENT_REPOSITORY,
      useClass: AgentFsRepository,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function GetConfig() {
  const jsonFile = `/assets/config.json`; //path to config file
  const request = new XMLHttpRequest();
  request.open('GET', jsonFile, false); // get app settings
  request.send(null);
  return JSON.parse(request.responseText);
}
