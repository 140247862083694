import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { Agent } from '../models/agent';

export interface AgentRepository {
  getAgent(): Observable<Agent[]>;
}

export const AGENT_REPOSITORY: InjectionToken<AgentRepository> = new InjectionToken<AgentRepository>(
  'AGENT_REPOSITORY',
);
